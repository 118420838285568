import Commercial, {CommercialType} from "@/Atoms/Commercial";


const AdvertisementSection = (props: any) => {
  return <section {...props}>
    <div className={'container flex justify-center'}>
      <Commercial type={CommercialType.Leaderboard}/>
    </div>
  </section>
}

export default AdvertisementSection;
